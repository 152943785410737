import React from 'react';
import style from "./index.module.scss";

type BurgerMenuProps = {
    onClickHundler: Function;
  };

const BurgerMenu = ({onClickHundler} : BurgerMenuProps) => {
    return (
        <div className={`${style.f_hamburger_switch} btn`} onClick={() => {onClickHundler()}}>
            <div className={style.cont}>
                <div className={`${style.bx} ${style.bx1}`}></div>
                <div className={`${style.bx} ${style.bx2}`}></div>
                <div className={`${style.bx} ${style.bx3}`}></div>
                <div className={`${style.bx} ${style.bx4}`}></div>
            </div>
        </div>
    )
}

export default BurgerMenu;