import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
import Link from "next/link";
import BurgerMenu from "../BurgerMenu";
import CloseBtn from "../../components/close_btn";
import style from "./index.module.scss";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 62,
	height: 34,
	padding: 7,
	"& .MuiSwitch-switchBase": {
		margin: 1,
		padding: 0,
		transform: "translateX(6px)",
		"&.Mui-checked": {
			color: "#fff",
			transform: "translateX(22px)",
			"& .MuiSwitch-thumb:before": {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
					"#fff"
				)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
			},
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor:
					theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
		width: 32,
		height: 32,
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 0,
			top: 0,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
				"#fff"
			)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
		},
	},
	"& .MuiSwitch-track": {
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
		borderRadius: 20 / 2,
	},
}));

type headerProps = {
	onSupport: any;
	isDark: boolean;
	setIsDark: () => void;
};

const personalElementsLeft = [
	{
		text: "Основная страница",
		link: "/",
	},
	{
		text: "Все категории",
		link: "/categories",
	},
];

const personalElementsRight = [
	{
		text: "Премьера",
		link: "/premier",
	},
	{
		text: "Книги",
		link: "/books",
	},
	{
		text: "Все расказы",
		link: "/stories",
	},
];

const middleElements = [
	{
		text: "Основная страница",
		link: "/",
	},
];

const AppHeader = ({ onSupport, isDark, setIsDark }: headerProps) => {
	// const { getPersonals } = useData()
	// const [openMenu, setOpenMenu] = useState(false);

	const mobileMenu = useRef<HTMLInputElement>(null);
	const [clientWidth, setClientWidth] = useState(0);
	const [_window, setWindow] = useState(null);

	useEffect(() => {
		if (typeof window !== "undefined") {
			setWindow(window);
			window.scrollTo(0, 0);

			window.addEventListener("resize", handleResize);
			handleResize();
			return () => window.removeEventListener("resize", handleResize);
		}
	}, []);

	const handleResize = () => {
		setClientWidth(document.documentElement.clientWidth);
	};

	const handleOpenMenu = () => {
		mobileMenu.current?.classList.add("mobile_opened--open");
	};

	const onClose = () => {
		mobileMenu.current?.classList.remove("mobile_opened--open");
	};

	return (
		<header className={style.header}>
			<div className={style.switch_theme}>
				<FormGroup>
					<FormControlLabel
						control={
							<MaterialUISwitch
								sx={{ m: 1 }}
								checked={isDark}
								onChange={setIsDark}
							/>
						}
						label=""
					/>
				</FormGroup>
			</div>
			{/* <div className='story_btns flexbox-rows align-items-center justify-content-end'>
            <DeleteButton onClickHundler={() => {}}/>
            <EditButton onClickHundler={() => {}}/>
          </div> */}
			{/* <div className={`${style.author_line} ${style.image_line}`}>
            <picture>
                <source srcSet={imgWebp} type="image/webp"/>
                <source srcSet={img} type="image/jpg" />
                <img className="img" src={img} alt="Author photo" />
            </picture> 
          </div> */}

			<div className={style.author_line}>
				<div>
					<div>
						<p className={style.author_name}>
							{/* {getPersonals()?.pseudonym ? 
                  getPersonals().pseudonym
                  : <span>{getPersonals()?.name}  {getPersonals()?.lastname}</span>} */}
							Alina Avelis
						</p>
						<a href="mailto:alinaavelis@gmail.com">
							alinaavelis@gmail.com
						</a>
					</div>
					{clientWidth < 576 && (
						// <p> Основной жанр - <span className='space-no-wrap'>{getPersonals()?.main_genre}</span>
						<p>
							{" "}
							Основной жанр -{" "}
							<span className="space-no-wrap">
								приключения/фэнтэзи
							</span>
						</p>
					)}
				</div>

				{clientWidth < 576 && (
					<div>
						<BurgerMenu
							onClickHundler={() => {
								handleOpenMenu();
							}}
						/>
					</div>
				)}
			</div>

			<div className="mobile_opened" ref={mobileMenu}>
				<div className={style.author_line}>
					{/* {window.innerWidth > 576 && <p> Основной жанр - <span className='space-no-wrap'>{getPersonals()?.main_genre}</span></p> } */}
					{clientWidth > 576 && (
						<p>
							{" "}
							Основной жанр -{" "}
							<span className="space-no-wrap">
								приключения/фэнтэзи
							</span>
						</p>
					)}
					{clientWidth < 576 && (
						<div className="modal_top_close">
							<CloseBtn clickHandler={onClose} />
						</div>
					)}

					<div className={style.switch_theme_mobile}>
						<FormGroup>
							<FormControlLabel
								control={
									<MaterialUISwitch
										sx={{ m: 1 }}
										checked={isDark}
										onChange={setIsDark}
									/>
								}
								label=""
							/>
						</FormGroup>
					</div>

					<div className="flexbox-rows align-items-center justify-content-end">
						<button
							className={`btn btn_no_bg accent_text ${style.header_btn}`}
							onClick={onSupport}
						>
							Поддержать
						</button>
						<a
							href="https://t.me/Alina_Avelis_author"
							target="_blank"
							className="btn btn_no_bg"
							rel="noreferrer"
						>
							Подписаться
						</a>
					</div>
				</div>

				<div className={style.author_line}>
					<nav>
						<div className="flexbox-rows align-items-center justify-content-end">
							{personalElementsLeft.map((item, i) => {
								return (
									<Link
										key={"nav" + i}
										href={item.link}
										className={style.header__link}
										onClick={() => {
											onClose();
										}}
									>
										{item.text}
									</Link>
								);
							})}
						</div>

						<div className="flexbox-rows align-items-center justify-content-end">
							{personalElementsRight.map((item, i) => {
								return (
									<Link
										key={"nav" + i}
										href={item.link}
										className={style.header__link}
										onClick={() => {
											onClose();
										}}
									>
										{item.text}
									</Link>
								);
							})}
						</div>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default AppHeader;
